import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css'; // CSS file for styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section">
        <h3>Services</h3>
        <ul>
          <li><Link className="footer-link" to="/tallyprime">Tally Prime</Link></li>
          <li><Link className="footer-link" to="/MobileDeveloper">Mobile App Development</Link></li>
          <li><Link className="footer-link" to="/WebDevelopment">Web Development</Link></li>
          <li><Link className="footer-link" to="/HardwareServices">Hardware</Link></li>
          <li><Link className="footer-link" to="/SecuritySystem">CCTV</Link></li>
          <li><Link className="footer-link" to="/ServerPage">Server Management</Link></li>
          <li>Tally Support</li>
        </ul>
      </div>

      <div className="footer-section">
        <h3>Useful Links</h3>
        <ul>
          <li><Link className="footer-link" to="/contact">Contact Us</Link></li>
          <li><Link className="footer-link" to="/AboutPage">About Us</Link></li>
          <li><Link className="footer-link" to="/career_pages">Works</Link></li>

          {/* <li>Privacy Policy</li>
          <li>Terms & Conditions</li> */}
        </ul>
        <div className="contact-info">
          <p>CONTACT</p>
          <p>+91 9841609990</p>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="social-icons">
          <a href="https://www.facebook.com/imatrix.pvt" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook"></i>
          </a>
          <a href="https://www.instagram.com/imatrix.t/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://in.linkedin.com/company/imatrix-t" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin"></i>
            
          </a>
          <a href="https://www.youtube.com/@imatrixtechnologies1833/featured" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-youtube"></i>
          </a>
        </div>
        <p>Copyright © 2024 | iMatrix Technologies Pvt Ltd</p>
        <div className="footer-links">
        Terms of Use | Privacy Policy
        </div>
      </div>
    </footer>
  );
};

export default Footer;
