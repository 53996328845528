import React, { useState, useEffect } from "react";
import "./WebDevelopment.css"; // Add your custom CSS for styling and animations

const WebDevelopment = () => {
  // State to track current image index for each section
  const [currentImageIndexTransport, setCurrentImageIndexTransport] = useState(0);
  const [currentImageIndexPR, setCurrentImageIndexPR] = useState(0);
  const [currentImageIndexEwarehouse, setCurrentImageIndexEwarehouse] = useState(0);

  // Arrays of images for each section
  const transportImages = [
    "/images/webimage/1.png",
    "/images/webimage/2.png",
    "/images/webimage/3.png",
    "/images/webimage/4.png",
    "/images/webimage/5.png",
  ];

  const prImages = [
    "/images/prmodel/1.jpg",
    "/images/prmodel/2.jpg",
    "/images/prmodel/3.jpg",
    "/images/prmodel/4.jpg",
    "/images/prmodel/5.jpg",
    "/images/prmodel/6.jpg",
    "/images/prmodel/7.jpg",
  ];

  const eWarehouseImages = [
    "/images/webimage/ewhere/1.png",
    "/images/webimage/ewhere/2.png",
    "/images/webimage/ewhere/3.png",
    "/images/webimage/ewhere/4.png",
    "/images/webimage/ewhere/5.png",
    "/images/webimage/ewhere/6.png",
  ];

  // Change image every 3 seconds for each section
  useEffect(() => {
    const transportInterval = setInterval(() => {
      setCurrentImageIndexTransport((prevIndex) =>
        prevIndex === transportImages.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);
    
    const prInterval = setInterval(() => {
      setCurrentImageIndexPR((prevIndex) =>
        prevIndex === prImages.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    const eWarehouseInterval = setInterval(() => {
      setCurrentImageIndexEwarehouse((prevIndex) =>
        prevIndex === eWarehouseImages.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => {
      clearInterval(transportInterval);
      clearInterval(prInterval);
      clearInterval(eWarehouseInterval);
    };
  }, [transportImages.length, prImages.length, eWarehouseImages.length]);

  return (
    <div className="WebDevelopment-container">
      {/* Transport Web Application Section */}

      <br/>
      <br/>
      <br/>
      <br/>
      <section className="section transport-section">
        <div className="image">
          <img
            src={transportImages[currentImageIndexTransport]}
            alt="Transport Web Application"
            className="slider-image"
          />
        </div>
        <div className="text">

          <h3>Logistics modules</h3>
          <p>
            Our Transport Web Application streamlines logistics and transportation management, allowing
            businesses to optimize routes, manage shipments, and enhance overall efficiency. 
          </p>
        </div>
      </section>

      {/* PR Purchase Section */}
      <section className="section pr-purchase-section">
        <div className="text">
          <h3>Purchase Request Modules</h3>
          <p>
            The PR Purchase system simplifies procurement processes, providing a user-friendly interface 
            for managing purchase requests, approvals, and vendor interactions effectively.
          </p>
        </div>
        <div className="image">
          <img
            src={prImages[currentImageIndexPR]}
            alt="PR Purchase"
            className="slider-image"
          />
        </div>
      </section>

      {/* E-warehouse Section */}
      <section className="section e-warehouse-section">
        <div className="image">
          <img
            src={eWarehouseImages[currentImageIndexEwarehouse]}
            alt="E-warehouse"
            className="slider-image"
          />
        </div>
        <div className="text">
          <h3>E-Warehouse Data Storage</h3>
          <p>
            Our E-warehouse solution provides a comprehensive platform for managing inventory, 
            order fulfillment, and real-time analytics to ensure your supply chain operates seamlessly.
          </p>
        </div>
      </section>

      <section className="tech-section">
        <div className="container2">
          {/* Technology We Used */}
          <div className="technology-used">
            <h2>Technology We Used</h2>
            <p>
              Technology stacks are the backbone of your architected software. We build your software
              with the latest and top core technologies.
            </p>
            <div className="tech-icons">
              <img src="images/mobile/mobileapps/xd.png" alt="Adobe XD" />
              <img src="images/mobile/mobileapps/figma.png" alt="Figma" />
              <img src="images/mobile/mobileapps/react.png" alt="React Native" />
              <img src="images/732212.png" alt="Flutter" />
              <img src="images/5968292.png" alt="Ionic" />
              <img src="images/mobile/mobileapps/sql.png" alt="MySQL" />
            </div>
          </div>

          {/* App Design and Development Services */}
          <div className="services-section">
            <h2>Our Diverse Web Application Design and Development Services</h2>
            <p>
              We have mastered web application development services for over a decade and keeping up with the
              newest technologies, our app development stands out among the most.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WebDevelopment;
