import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPaperPlane,
  faUser,
  faHandshake,
  faChartLine,
  faMedal,
  faBriefcase,
  faQuestionCircle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for toast notifications
import './CareerPage.css';

const CareerPage = () => {
  const [resume, setResume] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contact: '',
    yearOfGraduation: '',
    gender: '',
    experienceInYears: '',
    currentEmployer: '',
    currentCTC: '',
    expectedCTC: '',
    noticePeriod: '',
    skillSet: '',
    source: '',
    currentLocation: '',
    preferredLocation: '',
  });

  const handleResumeUpload = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      const validExtensions = /pdf|doc|docx/i;
      if (!validExtensions.test(uploadedFile.name)) {
        setError('Invalid file type. Please upload a PDF or Word document.');
        setResume(null); // Reset resume if invalid
      } else {
        console.log('File uploaded:', uploadedFile);
        setResume(uploadedFile);
        setError(''); // Clear any previous error
      }
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      contact: '',
      yearOfGraduation: '',
      gender: '',
      experienceInYears: '',
      currentEmployer: '',
      currentCTC: '',
      expectedCTC: '',
      noticePeriod: '',
      skillSet: '',
      source: '',
      currentLocation: '',
      preferredLocation: '',
    });
    setResume(null);
    setSubmitted(false);
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSubmitted(false);
    setError('');

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    if (resume) {
      formDataToSend.append('pdf', resume);
    } else {
      setError('Please upload your resume.');
      setLoading(false);
      return; // Prevent submission if no resume is uploaded
    }

    console.log('Form data being sent:', Array.from(formDataToSend.entries()));

    try {
      const response = await axios.post('https://kesvaan-6.onrender.com/apply', formDataToSend, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      console.log('Data successfully sent to backend!', response.data);
      resetForm();
      setSubmitted(true);
      toast.success('Thank you for applying! We’ll review your application soon.'); // Show success toast
  
      // Reload the page after submission
       setTimeout(() => {
      window.location.reload(); // This will refresh the page after a short delay
    }, 1000); // Adjust delay if needed


    } catch (error) {
      console.error('Failed to send data to backend:', error);
      setError('There was an error submitting your application. Please try again.');
      toast.error(
        <span>
          <FontAwesomeIcon icon={faExclamationCircle} /> {error.message}
        </span>
      ); // Show error toast with icon
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="career-container">
      <header className="career-header">
     <br/>
        <h1>Join Our Team</h1>
        <p>Discover opportunities to shape the future with us.</p>
      </header>

      {/* Company Culture Section */}
      <section className="career-section culture">
        <h2>Our Company Culture</h2>
        <p>We value innovation, collaboration, and growth. Join a workplace that inspires excellence.</p>
        <div className="culture-images">
          <img src="/images/effective-team-collaboration-min.jpg" alt="Team Collaboration" className="culture-image" />
          <img src="/images/community-engagement-1.avif" alt="Community Engagement" className="culture-image" />
          <img src="/images/0922-benefits-of-offering-employee-training-development-Inline1.webp" alt="Employee Development" className="culture-image" />
        </div>
      </section>

      {/* Career Development Section */}
      <section className="career-section development">
        <h2>Career Development</h2>
        <ul className="benefits-list">
          <li>
            <FontAwesomeIcon icon={faChartLine} className="icon" /> Learning Programs
          </li>
          <li>
            <FontAwesomeIcon icon={faHandshake} className="icon" /> Mentorship & Guidance
          </li>
          <li>
            <FontAwesomeIcon icon={faPaperPlane} className="icon" /> Career Growth Pathways
          </li>
        </ul>
      </section>

      {/* Employee Benefits Section */}
      <section className="career-section benefits">
        <h2>Employee Benefits</h2>
        <ul className="benefits-list">
          <li>
            <FontAwesomeIcon icon={faMedal} className="icon" /> Health & Wellness Programs
          </li>
          <li>
            <FontAwesomeIcon icon={faBriefcase} className="icon" /> Flexible Hours
          </li>
          <li>
            <FontAwesomeIcon icon={faUser} className="icon" /> Paid Time Off & Parental Leave
          </li>
        </ul>
      </section>

      {/* Why Work With Us Section */}
      <section className="career-section why-work-with-us">
        <h2>Why Work With Us</h2>
        <p style={{ textAlign: 'justify', fontSize: '18px' }}>
          At our company, we believe in fostering a positive and inclusive workplace. Here are a few reasons to consider joining our team:
        </p>
        <ul>
          <li>Collaborative Environment: We encourage teamwork and open communication.</li>
          <li>Growth Opportunities: We provide various learning and development programs to help you succeed.</li>
          <li>Work-Life Balance: We value your time and offer flexible working arrangements.</li>
          <li>Community Engagement: We actively participate in community service and support local initiatives.</li>
        </ul>
      </section>

      {/* FAQs Section */}
      <section className="career-section faq">
        <h2>FAQs</h2>
        <div className="faq-item">
          <FontAwesomeIcon icon={faQuestionCircle} className="icon" />
          <p>
            <strong>What is the hiring process?</strong>
          </p>
          <p>Apply online, and we’ll follow up with qualified candidates for an interview.</p>
        </div>
      </section>

      {/* Application Form Section */}
      <section className="career-section apply">
        <h2>Apply Now</h2>
        <p>We look forward to learning about your skills and experience.</p>
        <form className="career-form" onSubmit={handleSubmit}>
          {Object.keys(formData).map((key, idx) => {
            const label = key
              .replace(/([A-Z])/g, ' $1')
              .replace(/^./, (str) => str.toUpperCase());
            return (
              <div key={idx} className="form-group">
                <label>{label} *</label>
                {key === 'gender' ? (
                  <select
                    name={key}
                    value={formData[key]}
                    onChange={handleInputChange}
                    required
                    className="gender-select"
                  >
                    <option value="">Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Transgender">Transgender</option>
                  </select>
                ) : (
                  <input
                    type={key.includes('email') ? 'email' : 'text'}
                    name={key}
                    value={formData[key]}
                    onChange={handleInputChange}
                    required
                    aria-label={label} // Added accessibility label
                  />
                )}
              </div>
            );
          })}
          <div className="form-group">
            <label>Upload Your Resume *</label>
            <input
              type="file"
              accept=".pdf,.doc,.docx"
              onChange={handleResumeUpload}
              required
              aria-label="Upload Your Resume" // Added accessibility label
            />
          </div>
          <div className="form-actions">
            <button type="button" className="cancel-button" onClick={resetForm}>Cancel</button>
            <button type="submit" className="submit-button" disabled={loading}>
              {loading ? 'Submitting...' : 'Submit Application'}
            </button>
          </div>
        </form>
        {submitted && (
          <div className="thank-you-message">
          
          </div>
        )}
        {error && (
          <div className="error-message" style={{ color: 'red' }}>
            <FontAwesomeIcon icon={faExclamationCircle} />
            <span>{error}</span>
          </div>
        )}
      </section>
      <ToastContainer /> {/* Toast container for notifications */}
    </div>
  );
};

export default CareerPage;
