import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './homepage/Home';
import IMatrixComponent from './hero/IMatrixComponent';
import TallyPrime from './tallyprime/TallyPrime';
import TallyShowcase from './tallyprime/TallyShowcase';
import Footer from './footer/Footer';
import Timeline from './timelines/Timeline';
import Content_New from './content_new/Content_New';
import Client from './Client/Client';
import AboutPage from './AboutPage/AboutPage';
import HardwareServices from './HardwareServices/HardwareServices';
import SecuritySystem from './SecuritySystem/SecuritySystem';
import MobileDeveloper from './MobileDeveloper/MobileDeveloper';
import WebDevelopment from './WebDevelopment/WebDevelopment';
import ServicePage from './ServicePage/ServicePage';
import ServerPage from './ServerPage/ServerPage';
import ProductGrid from './BIO/ProductGrid';
import Antivirus from './AntivirusF/Antivirus';
import AutoBackupSoftware from './autoBackupSoftware/AutoBackupSoftware';
import PrinterSoftware from './PrinterSoftware/PrinterSoftware';
import WebList from './webcat/WebList';
import EWarehouse from './e_data/EWarehouse';
import PRPurchaseRequest from './pr/PRPurchaseRequest';
import TransportWebApplication from './tr/TransportWebApplication';
import './App.css';
import Header from './homepage/Header';
import ScrollToTop from './ScrollToTop';
import NotFound from './NotFound';
import CareerPage from './careers_pages/CareerPage';

function App() {
  return (
    <div className="App">
      <Header />
     
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/imatrix" element={<IMatrixComponent />} />
        <Route path="/tallyprime" element={<TallyPrime />} />
        <Route path="/tallyshowcase" element={<TallyShowcase />} />
        <Route path="/timelines" element={<Timeline />} />
        <Route path="/contact" element={<Content_New />} />
        <Route path="/Client" element={<Client />} />
        <Route path="/AboutPage" element={<AboutPage />} />
        <Route path="/HardwareServices" element={<HardwareServices />} />
        <Route path="/SecuritySystem" element={<SecuritySystem />} />
        <Route path="/MobileDeveloper" element={<MobileDeveloper />} />
        <Route path="/WebDevelopment" element={<WebDevelopment />} />
        <Route path="/ServicePage" element={<ServicePage />} />
        <Route path="/ServerPage" element={<ServerPage />} />
        <Route path="/BIO" element={<ProductGrid />} />
        <Route path="/Antivirus" element={<Antivirus />} />
        <Route path="/autoBackupSoftware" element={<AutoBackupSoftware />} />
        <Route path="/PrinterSoftware" element={<PrinterSoftware />} />
        <Route path="/webcat" element={<WebList />} />
        <Route path="/e_data" element={<EWarehouse />} />
        <Route path="/pr" element={<PRPurchaseRequest />} />
        <Route path="/tr" element={<TransportWebApplication />} />
        <Route path="/career_pages" element={<CareerPage />} />
        <Route path="*" element={<NotFound />} />

      </Routes>
      <Footer />
    </div>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
