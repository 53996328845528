// src/pages/TransportWebApplication.jsx
import React from 'react';
import '../e_data/EWarehouse.css'; // Ensure you have the correct CSS import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck, faClipboardCheck, faChartLine, faSyncAlt, faLock } from '@fortawesome/free-solid-svg-icons';

const TransportWebApplication = () => {
  return (
    <div className="e-warehouse">
      <h3>Transport Logistics</h3>
      <div className="content-container">
        <div className="cardw">
          <FontAwesomeIcon icon={faTruck} className="icon" />
          <p>
            Our Transport Web Application is designed to streamline logistics and fleet management, offering a comprehensive 
            solution that simplifies the complexities of transportation processes. With real-time tracking and management 
            tools, businesses can monitor their fleet's status and optimize their logistics operations for greater efficiency.
          </p>
        </div>
        <div className="cardw">
          <FontAwesomeIcon icon={faClipboardCheck} className="icon" />
          <p>
            The application features a user-friendly interface that allows users to manage transport requests, assign 
            drivers, and schedule deliveries with ease. This functionality ensures that all transport activities are well-coordinated 
            and that resources are utilized effectively.
          </p>
        </div>
        <div className="cardw">
          <FontAwesomeIcon icon={faChartLine} className="icon" />
          <p>
            Advanced analytics and reporting capabilities provide insights into key performance metrics such as delivery 
            times, fuel consumption, and route efficiency. By leveraging this data, businesses can make informed decisions 
            to enhance their transport operations and reduce costs.
          </p>
        </div>
        <div className="cardw">
          <FontAwesomeIcon icon={faSyncAlt} className="icon" />
          <p>
            Our Transport Web Application integrates seamlessly with existing supply chain management systems, ensuring 
            that all transport data is synchronized with other business operations. This integration improves communication 
            across departments and enhances overall operational efficiency.
          </p>
        </div>
        <div className="cardw">
          <FontAwesomeIcon icon={faLock} className="icon" />
          <p>
            Security is a top priority in our application. Role-based access controls ensure that sensitive information 
            is protected and only accessible to authorized personnel. This helps maintain compliance with industry regulations 
            and safeguards critical business data.
          </p>
        </div>
        <div className="cardw">
          <FontAwesomeIcon icon={faTruck} className="icon" />
          <p>
            The application also provides a mobile-friendly interface, allowing drivers to access transport details on-the-go. 
            This mobile capability enhances communication between drivers and dispatchers, ensuring that all parties are 
            updated in real-time about changes or delays.
          </p>
        </div>
      </div>
      <img 
        src={require("./image.png")} 
        alt="Transport Web Application" 
        className="warehouse-image" 
      />
    </div>
  );
};

export default TransportWebApplication;
