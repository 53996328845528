import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { FaCheckCircle, FaMapMarkerAlt, FaMapMarkedAlt, FaEnvelope, FaPhone, FaWhatsapp } from 'react-icons/fa';
import './ContactUs.css';

const PUBLIC_KEY = 'FXRINrJwtfHmHpCIT';
const SERVICE_ID = 'service_00vmrqp';
const TEMPLATE_ID = 'template_ign8guc';

const Content_New = () => {
  const form = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    mobileNumber: '',
    email: '',
    product: '',
    message: '',
  });

  const sendEmail = (e) => {
    e.preventDefault();

    const templateParams = {
      to_name: 'Recipient',
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      mobile_number: formData.mobileNumber,
      company: formData.companyName,
      product: formData.product,
      message: formData.message,
    };

    emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, PUBLIC_KEY)
      .then(() => {
        setIsModalOpen(true);
        form.current.reset();
        setFormData({
          firstName: '',
          lastName: '',
          companyName: '',
          mobileNumber: '',
          email: '',
          product: '',
          message: '',
        });
      })
      .catch((error) => {
        console.error('Email sending error:', error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <section className="contacts padding">
      <div className="container shadow flexSB">

        {/* Left Section */}
        <div className="left row">
          <h1>Contact Information</h1>
          <div className="contact-info">
            <div className="box">
            <h4 style={{ display: 'flex', alignItems: 'center' }}>

  <span style={{ marginLeft: '280px', textAlign: 'center' , marginTop:'-120px'}}>  < FaMapMarkerAlt />Location:</span>
  <a
    href="https://www.google.com/maps/place/iMatrix+Technologies+Pvt+Ltd/@13.0687222,80.2138012,17z/data=!3m1!4b1!4m6!3m5!1s0x3a5266972fffffff:0x92791e68a0950605!8m2!3d13.0687222!4d80.2163761!16s%2Fg%2F1tdqfx71?entry=ttu&g_ep=EgoyMDI0MTAxNi4wIKXMDSoASAFQAw%3D%3D"
    target="_blank"
    rel="noopener noreferrer"
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginLeft: '200px',
      position:'relative',
      top:'-100px',
      textDecoration: 'none',
      color: '#007bff',
    }}
  >
    <FaMapMarkedAlt
      style={{
        border: '1px solid black',
        borderRadius: '50%',
        padding: '15px',
      }}
      size={50}
    />
    <span style={{ marginTop: '10px' }}>Click Here</span>
  </a>
</h4>

              <p style={{marginTop:'-100px'}}>
                iMatrix Technologies Pvt Ltd <br />
                #R-76 M M D A Colony, Arumbakkam, <br />
                Chennai - 600 106
              </p>
            </div>
            <div className="contact-info-container">
              <div className="box">
                <h4><FaEnvelope /> Email</h4>
                <div className="contact-details">
                  <div className="contact-item">
                    <strong><FaEnvelope /> CUSTOMIZATION:</strong>
                    <p>
                      tdl@imatrixtechnologies.com <br />
                      <FaPhone /> +91 9841594294 , +91 9841542801
                    </p>
                  </div>
                  <div className="contact-item">
                    <strong><FaEnvelope /> SUPPORT:</strong>
                    <p>
                      support@imatrixtechnologies.com <br />
                      <FaPhone /> +91 9841609990, +91 9841564455
                    </p>
                  </div>
                  <div className="contact-item">
                    <strong><FaEnvelope /> MARKETING:</strong>
                    <p>
                      marketing@imatrixtechnologies.com <br />
                      <FaPhone /> +91 9841609963, +91 9962000567
                    </p>
                  </div>
                  <div className="contact-item">
                    <strong><FaEnvelope /> HARDWARE:</strong>
                    <p>
                      hardware@imatrixtechnologies.com <br />
                      <FaPhone /> +91 9841545557
                    </p>
                  </div>
                  <div className="contact-item">
                    <strong><FaEnvelope /> SOFTWARE:</strong>
                    <p>
                      development@imatrixtechnologies.com <br />
                      <FaPhone /> +91 9500625633
                    </p>
                  </div>
                  <div className="contact-item">
                    <strong><FaWhatsapp /> WhatsApp:</strong>
                    <p> +91 9841609990 </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="right row">
          <h1>Get In Touch</h1>
          <form ref={form} className="contact-form" onSubmit={sendEmail}>
            <div className="form-group">
              <input type="text" name="firstName" placeholder="First Name" required onChange={handleChange} />
              <input type="text" name="lastName" placeholder="Last Name" required onChange={handleChange} />
            </div>
            <input style={{width:'94%'}} type="text" name="companyName" placeholder="Company Name" required onChange={handleChange} />
            <input style={{width:'94%'}} type="text" name="mobileNumber" placeholder="Mobile Number" required onChange={handleChange} />
            <input style={{width:'94%'}} type="email" name="email" placeholder="Email Address" required onChange={handleChange} />
            <input style={{width:'94%'}} type="text" name="product" placeholder="Product" required onChange={handleChange} />
            <textarea name="message" placeholder="Your Message" required onChange={handleChange}></textarea>
            <button type="submit" className="primary-btn">Send Message</button>
          </form>
        </div>
      </div>

      {/* Modal Popup for Success Message */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2><FaCheckCircle style={{ color: 'green', marginRight: '8px' }} />Thank you!</h2>
            <p>Our representative will contact you within 24 hours on working days.</p>
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )}

      {/* Google Maps Section */}
      <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.2481050359854!2d80.21176271425258!3d13.06872741341825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266972fffffff%3A0x92791e68a0950605!2siMatrix%20Technologies%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1695298143896!5m2!1sen!2sin"
            width="600"
            height="500px"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
    </section>
  );
};

export default Content_New;
