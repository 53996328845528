import React from 'react';
import { FaServer, FaDatabase, FaShieldAlt } from 'react-icons/fa'; // Import icons from React Icons
import './ServerPage.css'; // Import your CSS file
import serverImage from './datacenter-horizontal-banner-set_1284-20144.jpg'; // Replace with the path to your server image

const ServerPage = () => {
  return (
    <div className="server-page">
      <div className="server-content">\
      <br/><br/>
        <h1 className="page-title">Reliable Server Solutions</h1>
        <p className="intro-text">
          We offer robust web hosting services with a focus on data maintenance
          and top-notch firewall security. Our servers are equipped to handle
          all your business needs with performance, security, and reliability.
        </p>

        <div className="services-container">
          <div className="service">
            <FaServer className="service-icon" />
            <h2>Web Hosting</h2>
            <p>
              Our fast and secure web hosting solutions ensure your website is always up and running. With high uptime and optimized performance, your customers will have a seamless experience.
            </p>
          </div>

          <div className="service">
            <FaDatabase className="service-icon" />
            <h2>Data Maintenance</h2>
            <p>
              We ensure your data is backed up regularly and securely maintained, offering peace of mind and reliability for your business.
            </p>
          </div>

          <div className="service">
            <FaShieldAlt className="service-icon" />
            <h2>Firewall Security</h2>
            <p>
              Protect your sensitive data from cyber threats with our advanced firewall security solutions, ensuring safe online transactions and data integrity.
            </p>
          </div>
        </div>
      </div>

      <div className="server-image">
        <img src={serverImage} alt="Server Hosting" />
      </div>
    </div>
  );
};

export default ServerPage;
