import React from 'react';
import './ProductGrid.css';
import { MdSecurity, MdAccessTime, MdLocationCity, MdDataUsage, MdElevator, MdFingerprint, MdFace, MdPerson, MdRoomService } from 'react-icons/md';

const solutionsData = [
  {
    title: "Access Control",
    description: "Enhance sensitive areas with advanced features and ensure fool-proof security.",
    icon: <MdSecurity />
  },
  {
    title: "Time-Attendance",
    description: "Centralized management of attendance policies across multiple locations.",
    icon: <MdAccessTime />
  },
  {
    title: "Access Control for Single Location",
    description: "Comprehensive security infrastructure for SOHO and SMBs with panels, controllers, readers, and more.",
    icon: <MdLocationCity />
  },
  {
    title: "Access Control for Data Centers",
    description: "Empower data centers with robust access control measures to preserve vital information.",
    icon: <MdDataUsage />
  },
  {
    title: "Elevator Access Control",
    description: "Enable precise control over floor access with advanced features.",
    icon: <MdElevator />
  },
  {
    title: "Aadhar Enabled Biometric Attendance Solution",
    description: "Enhance time attendance within government organizations.",
    icon: <MdFingerprint />
  },
  {
    title: "Employee Self Service",
    description: "Check entitlements and access real-time work-related information.",
    icon: <MdPerson />
  },
  {
    title: "Visitor Management",
    description: "Professional solution addressing security, hospitality, and productivity.",
    icon: <MdPerson />
  },
  {
    title: "Cafeteria Management",
    description: "Comprehensive management from order placement to delivery and payment.",
    icon: <MdRoomService />
  },
];

const products = [
  {
    title: "Fingerprint Biometrics",
    description: "Advanced Fingerprint Recognition System for Enhanced Security. It offers quick and accurate fingerprint scanning technology, ensuring maximum security in sensitive environments.",
    image: "/images/bio/biometric-fingerprint-security-system.jpg"
  },
  {
    title: "Facial Biometrics",
    description: "Next-Gen Facial Recognition Technology for Seamless Access Control. Utilizing artificial intelligence, it ensures fast and accurate recognition without the need for physical contact.",
    image: "/images/bio/MicrosoftTeams-image-246-1024x599 (1).jpg"
  },
  {
    title: "COSEC VEGA Series",
    description: "High-Performance Biometric Door Controller Equipped for High-Security Applications. This biometric solution provides seamless integration with various security systems and enhances access control in mission-critical environments.",
    image: "/images/bio/14998539935_2c11d8f7f9_c.jpg"
  },
  {
    title: "COSEC VEGA Series with Face Authentication",
    description: "High-Performance Biometric Door Controller Equipped for High-Security Applications with Face Authentication, Access Control, and Alarm Integration for Enhanced Protection.",
    image: "/images/bio/63185d29a8932dd05562f96a_Biometric-access-control-system-scene.jpg"
  }
];

const ProductGrid = () => (
  <div className="product-grid">
    {products.map((product) => (
      <div key={product.title} className="product-card">
        <div className="product-image">
          <img src={product.image} alt={product.title} />
        </div>
        <h3 className="product-title">{product.title}</h3>
        <p className="product-description">{product.description}</p>
      </div>
    ))}
  </div>
);

const Solutions = () => (
  <div className="solutions-container">
    {solutionsData.map((solution) => (
      <div className="solution-card" key={solution.title}>
        <div className="solution-icon">{solution.icon}</div>
        <h3>{solution.title}</h3>
        <p>{solution.description}</p>
      </div>
    ))}
  </div>
);

const App = () => (
  <div className="app-container">
    <br/><br/><br/>
    <h1 className="section-title">Our Product Offerings</h1>
    <ProductGrid />
    <h1 className="section-title">Our Security Solutions</h1>
    <Solutions />
  </div>
);

export default App;
