// src/pages/PRPurchaseRequest.jsx
import React from 'react';
import '../e_data/EWarehouse.css'; // Ensure you have the correct CSS import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faCheckCircle, faChartLine, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

const PRPurchaseRequest = () => {
  return (
    <div className="e-warehouse">
      <h3> Purchase Request Module Application</h3>
      <div className="content-container">
        <div className="cardw">
          <FontAwesomeIcon icon={faClipboardCheck} className="icon" />
          <p>
            Our Purchase Request Module Application is designed to streamline and simplify the purchase request process. 
            It enables organizations to efficiently manage purchase requests from initiation to approval, ensuring that 
            all requests are tracked and handled in a timely manner.
          </p>
        </div>
        <div className="cardw">
          <FontAwesomeIcon icon={faCheckCircle} className="icon" />
          <p>
            With this module, users can easily submit purchase requests online, eliminating the need for paper-based forms. 
            The application supports customizable workflows, allowing organizations to define approval hierarchies and 
            rules according to their specific needs.
          </p>
        </div>
        <div className="cardw">
          <FontAwesomeIcon icon={faChartLine} className="icon" />
          <p>
            The built-in reporting tools provide insights into purchase patterns, approval timelines, and budget usage, 
            helping organizations make informed decisions about their purchasing processes. By analyzing this data, 
            businesses can identify cost-saving opportunities and optimize their procurement strategies.
          </p>
        </div>
        <div className="cardw">
          <FontAwesomeIcon icon={faSyncAlt} className="icon" />
          <p>
            Our module integrates seamlessly with existing ERP systems, allowing for real-time updates and synchronization 
            of purchase requests with financial records. This integration ensures that all purchase-related data is 
            accurately reflected in the organization's financial reporting, minimizing discrepancies and improving accountability.
          </p>
        </div>
        <div className="cardw">
          <FontAwesomeIcon icon={faClipboardCheck} className="icon" />
          <p>
            Security is paramount in our application. Role-based access controls ensure that only authorized personnel 
            can approve or deny purchase requests, protecting sensitive financial data and maintaining compliance with 
            organizational policies.
          </p>
        </div>
        <div className="cardw">
          <FontAwesomeIcon icon={faChartLine} className="icon" />
          <p>
            The user-friendly interface is designed to enhance the user experience, making it easy for employees to 
            navigate and submit requests without extensive training. This reduces the learning curve and increases 
            adoption rates across the organization.
          </p>
        </div>
      </div>
      <img 
        src={require("./Screenshot (120).png")} 
        alt="PR Purchase Request Module" 
        className="warehouse-image" 
      />
    </div>
  );
};

export default PRPurchaseRequest;
