import React from "react";
import HeroSection from '../homepage/HeroSection'; // Adjusted component name to HeroSection
import IMatrixComponent from "../hero/IMatrixComponent"; // Corrected path to IMatrixComponent
import AboutPage from "../AboutPage/AboutPage"; // Corrected path to AboutPage

const Home = () => {
  return (
    <>
      <HeroSection /> {/* Corrected the component name */}
   
    </>
  );
};

export default Home;
