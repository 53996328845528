// src/pages/EWarehouse.jsx
import React from 'react';
import './EWarehouse.css'; // Make sure to import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faChartLine, faSyncAlt, faLock, faWarehouse, faCog } from '@fortawesome/free-solid-svg-icons'; // Import icons

const EWarehouse = () => {
  return (
    <div className="e-warehouse">
      <h3>E-warehouse</h3>
      <div className="content-container">
        <div className="cardw">
          <FontAwesomeIcon icon={faClipboardCheck} className="icon" />
          <p>
            Our E-warehouse solution is designed to revolutionize how you handle your supply chain management, bringing 
            speed, accuracy, and transparency to every step of the process. This platform offers a seamless integration 
            of inventory management, real-time order fulfillment, and advanced analytics to give you full control 
            and visibility over your warehouse operations.
          </p>
        </div>
        <div className="cardw">
          <FontAwesomeIcon icon={faWarehouse} className="icon" />
          <p>
            With E-warehouse, you can manage inventory across multiple locations, track stock levels in real time, 
            and automatically replenish items to ensure that you're never out of stock. The system integrates with 
            existing enterprise resource planning (ERP) systems and allows you to manage all your warehouses from a 
            single dashboard, simplifying operations for both small and large businesses.
          </p>
        </div>
        <div className="cardw">
          <FontAwesomeIcon icon={faCog} className="icon" />
          <p>
            Our powerful automation features are designed to reduce human error and streamline order fulfillment, 
            reducing manual tasks such as picking, packing, and shipping. By automating these processes, you can 
            significantly increase productivity, reduce overhead costs, and improve order accuracy, all while maintaining 
            customer satisfaction.
          </p>
        </div>
        <div className="cardw">
          <FontAwesomeIcon icon={faSyncAlt} className="icon" />
          <p>
            E-warehouse is built with scalability in mind, making it suitable for businesses of all sizes. Whether 
            you're running a small business or managing a large, complex distribution network, the platform adapts to 
            your specific needs. As your business grows, E-warehouse grows with you, allowing you to easily add new 
            locations, users, and functionality without any disruptions.
          </p>
        </div>
        <div className="cardw">
          <FontAwesomeIcon icon={faChartLine} className="icon" />
          <p>
            The intuitive dashboard provides comprehensive, real-time insights into key performance indicators (KPIs) 
            such as order fulfillment rates, inventory turnover, shipping costs, and more. With these analytics at 
            your fingertips, you can make data-driven decisions to optimize warehouse operations, reduce inefficiencies, 
            and ensure that your supply chain is operating at peak performance.
          </p>
        </div>
        <div className="cardw">
          <FontAwesomeIcon icon={faLock} className="icon" />
          <p>
            Security is also a top priority, with role-based access control ensuring that sensitive data is only 
            accessible to authorized personnel. Our cloud-based architecture guarantees uptime and performance, while 
            ensuring that your data is always backed up and protected.
          </p>
        </div>
        <div className="cardw">
          <FontAwesomeIcon icon={faClipboardCheck} className="icon" />
          <p>
            <strong>Tally Integration:</strong> E-warehouse seamlessly integrates with Tally, one of the leading accounting 
            software platforms, enabling real-time synchronization between your warehouse operations and your financial 
            records. With this integration, you can automatically update your inventory data, sales orders, and 
            purchase records in Tally, reducing the need for manual data entry and ensuring that your accounting system 
            always reflects the latest warehouse activity. This helps improve financial accuracy, streamline bookkeeping, 
            and provide a unified view of your business operations.
          </p>
        </div>
      </div>
      <img 
        src={require("./ewarehouse.gif")} 
        alt="E-Warehouse platform" 
        className="warehouse-image" 
      />
    </div>
  );
};

export default EWarehouse;
