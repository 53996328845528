import React from "react";
import "./Antivirus.css"; // Create a CSS file for styling

const Antivirus = () => {
  const services = [
    { name: "Antivirus Protection", imgSrc: "/images/anti/1.jpg", description: "Comprehensive antivirus protection for your devices." },
    { name: "Malware Removal", imgSrc: "/images/anti/10.png", description: "Thorough removal of malware from infected systems." },
    { name: "Firewall Protection", imgSrc: "/images/anti/9.jpg", description: "Advanced firewall solutions to protect your network." },
  ];

  const antivirusProducts = [
    { name: "K7 Antivirus", imgSrc: "/images/k7-total-security-1-pc-1-year-500x500-min-removebg-preview.png", description: "K7 provides comprehensive protection against all types of malware." },
    { name: "Norton Antivirus", imgSrc: "/images/anti/6__2_-removebg-preview.png", description: "Norton offers advanced security features to keep your devices safe." },
    { name: "McAfee Antivirus", imgSrc: "/images/anti/267210_ip13ww-removebg-preview.png", description: "McAfee provides all-in-one protection for your digital life." },
    { name: "Bitdefender Antivirus", imgSrc: "/images/anti/b.png", description: "Bitdefender uses innovative technology to provide unmatched malware protection." },
    { name: "Avast Antivirus", imgSrc: "/images/anti/a.png", description: "Avast offers essential protection against malware and other threats." },
    { name: "Malwarebytes", imgSrc: "/images/anti/watchdog-anti-malware-2020-1y1u-3d-en-500x500-500x500.webp", description: "Protect your devices with Malwarebytes Antivirus, ensuring real-time defense against malware" },
  ];

  return (
    <div className="service-page">
      <br/>
      <h1 className="main-title"> Antivirus Services</h1>
      <div className="services-container">
        {services.map((service, index) => (
          <div key={index} className="service-card">
            <img src={service.imgSrc} alt={service.name} className="service-img" />
            <h2>{service.name}</h2>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
      
      <h1 className="main-title">Recommended Antivirus Products</h1>
      <div className="antivirus-products-container">
        {antivirusProducts.map((product, index) => (
          <div key={index} className="product-card">
            <img src={product.imgSrc} alt={product.name} className="product-img" />
            <h2>{product.name}</h2>
            <p>{product.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Antivirus;
