import React from 'react';
import './TallyShowcase.css'; // CSS file for styling
import { Fade } from 'react-reveal'; // For animation

const TallyShowcase = () => {
  // List arrays sorted in alphabetical order
  const tallySoftware = [
    'New Tally Prime',
    'Tally Prime Auditors Edition',
    'Tally Prime Server',
    'Tally Software Services (TSS)',
    'Tally Virtual User (TVU) License'
  ].sort();

  const tallyServices = [
    'AMC / Annual Support Cover',
    'Data Synchronization',
    'Invoice Customization',
    'Priority Support Services',
    'Support – Onsite and Remote',
    'Training'
  ].sort();

  const tallySolutions = [
    'Business/Vertical Solutions',
    'Customization & Invoice Customization',
    'Excel to Tally Import Utility',
    'Integration',
    'Integration with Magento',
    'Integration with PHP, Java, ASP.NET',
    'New Tally API Integration',
    'Quickbook Tally Integration',
    'Voucher Formats Customizations',
    'eCommerce Integration'
  ].sort();

  const tallyPrime = [
    'Barcode and Label Printing',
    'Currency Forward Booking Contract',
    'Item Name Creator',
    'Quality Control (QC)',
    'Rack Wise Bin Wise Stock Management',
    'Sales Activity Management (SAM)',
    'Serial Number Management',
    'Supply Chain Visibility'
  ].sort();

  const tallyAddOns = [
    'Advance Transaction Level Security',
    'Audit Trail with Voucher History',
    'Digitally Signed Invoice',
    'GST Reminder',
    'Material Requirement Planning',
    'Multi File Attachment',
    'Multiple Branch Management',
    'One Click Outstanding',
    'SMS Module',
    'Sheet Magic – Excel Based Report Writer',
    'Smart Backup++'
  ].sort();

  return (
    <div className="tallyshow-container">
      <h1>Tally Features Overview</h1>
      <div className="tallyshow-sections">

        {/* Tally Software Section */}
        <Fade bottom>
          <div className="tallyshow-section">
            <h2>Tally Software</h2>
            <ul>
              {tallySoftware.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </Fade>

        {/* Tally Services Section */}
        <Fade bottom delay={200}>
          <div className="tallyshow-section">
            <h2>Tally Services</h2>
            <ul>
              {tallyServices.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </Fade>

        {/* Tally Solutions Section */}
        <Fade bottom delay={400}>
          <div className="tallyshow-section">
            <h2>Tally Solutions</h2>
            <ul>
              {tallySolutions.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </Fade>

        {/* Tally Prime Section */}
        <Fade bottom delay={600}>
          <div className="tallyshow-section">
            <h2>Tally Prime</h2>
            <ul>
              {tallyPrime.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </Fade>

        {/* Tally Add-Ons/Modules Section */}
        <Fade bottom delay={800}>
          <div className="tallyshow-section">
            <h2>Tally Add-Ons / Modules</h2>
            <ul>
              {tallyAddOns.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </Fade>

      </div>
    </div>
  );
};

export default TallyShowcase;
