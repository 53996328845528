// src/pages/PrinterSoftware.jsx
import React from "react";
import './PrinterSoftware.css'; // Keep the CSS file for styling

const PrinterSoftware = () => {
  const printerBrands = [
    { 
      category: "Laser Printers",
      description: "High-performance laser printers for home and office use. Designed to handle large volumes with minimal maintenance. These printers excel in speed and precision, making them ideal for high-demand environments. Suitable for both text and basic graphics printing needs.",
      details: "HP offers a range of laser printers known for speed and efficiency. Their toner technology reduces smudging, providing high-quality print output. HP's innovative features help reduce power consumption and save costs. Compatible with various media types for versatile printing.",
      image: "/printer/4.webp"
    },
    { 
      category: "Inkjet Printers",
      description: "High-quality inkjet printers for photo and document printing. Known for their vibrant color capabilities, they are perfect for producing sharp images. Inkjet printers are often preferred for personal and creative use. Ideal for those needing flexibility in media types, including photo paper.",
      details: "Canon's inkjet printers provide sharp images and vibrant colors. Designed for home users and photographers alike, Canon's ink system ensures longevity of prints. Easy to refill ink options make them cost-effective. Compact and user-friendly, with options for wireless connectivity.",
      image: "/printer/3.jpg"
    },
    { 
      category: "All-in-One Printers",
      description: "Multifunction printers with printing, scanning, and copying features. Excellent for small offices and home setups where space is a concern. These printers provide reliable performance for various document-handling needs. Simplifies tasks by combining multiple functions into one device.",
      details: "Epson's all-in-one printers are designed for versatility and productivity. Known for high-resolution scanning and quick copying speeds. Offers duplex printing options to save paper. Epson's eco-tank system reduces frequent refilling, making it an eco-friendly choice.",
      image: "/printer/2.jpg"
    },
    { 
      category: "Wireless Printers",
      description: "Wireless printers for seamless mobile and network printing. Allows users to print from anywhere in the office or home, increasing flexibility. Often come with mobile app support for easy operation. Perfect for a modern workspace setup without the need for cables.",
      details: "Brother's wireless printers offer easy connectivity for remote printing. Known for reliable and stable connections with fast setup. Brother’s ink and toner options ensure quality while saving costs. Suitable for both individual and shared network printing environments.",
      image: "/printer/1.webp"
    },
  ];

  const featureComparison = [
    { feature: "Print Speed", laser: "Fast", inkjet: "Moderate", allInOne: "Variable", wireless: "Variable" },
    { feature: "Color Quality", laser: "Good", inkjet: "Excellent", allInOne: "Good", wireless: "Good" },
    { feature: "Connectivity", laser: "Wired", inkjet: "Wired/Wireless", allInOne: "Wired/Wireless", wireless: "Wireless Only" },
  ];

 

  return (
    <div className="printer">
      <br/>
      <br/>
      <h1>Printers</h1>
      <div className="printer-container">
        {printerBrands.map((printer, index) => (
          <div key={index} className="printer-card">
            <img 
              src={printer.image} 
              alt={`${printer.category}`} 
              className="printer-image" 
              style={{ width: "200px", height: "150px", objectFit: "cover" }} 
            />
            <h2>{printer.category}</h2>
            <p>{printer.description}</p>
            <p className="details">{printer.details}</p>
          </div>
        ))}
      </div>
      
      {/* Feature Comparison Section */}
      <h2>Feature Comparison</h2>
      <table className="feature-comparison">
        <thead>
          <tr>
            <th>Feature</th>
            <th>Laser</th>
            <th>Inkjet</th>
            <th>All-in-One</th>
            <th>Wireless</th>
          </tr>
        </thead>
        <tbody>
          {featureComparison.map((row, index) => (
            <tr key={index}>
              <td>{row.feature}</td>
              <td>{row.laser}</td>
              <td>{row.inkjet}</td>
              <td>{row.allInOne}</td>
              <td>{row.wireless}</td>
            </tr>
          ))}
        </tbody>
      </table>

      
    </div>
  );
};

export default PrinterSoftware;
