import React, { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faStar, faCheckCircle, faAward, faLightbulb, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import "./Timeline.css";

const Timeline = () => {
  useEffect(() => {
    // Disable right-click on the page
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  const awards = [
    { year: "2006", title: "iMatrix Technologies had their journey in 2006", description: "The inception of iMatrix Technologies, focusing on innovative tech solutions.", presentedBy: "Founding Team", icon: faTrophy, image: "/images/awards/1.png", rating: 5 },
    { year: "2007", title: "Expansion of Services", description: "Launched new services to cater to a broader audience and market.", presentedBy: "Management Team", icon: faStar, image: "/images/awards/29.png", rating: 4 },
    { year: "2008", title: "Developed a strategy for technological solutions.", description: "Introduced strategic approaches to enhance service delivery and efficiency.", presentedBy: "Management Team", icon: faStar, image: "/images/awards/21.png", rating: 4 },
    { year: "2009", title: "Partnership with Key Industry Players", description: "Established strategic partnerships to enhance service offerings.", presentedBy: "Business Development Team", icon: faCheckCircle, image: "/images/awards/22.png", rating: 3 },
    { year: "2010", title: "Launch of Innovative Products", description: "Released cutting-edge products that set new industry standards.", presentedBy: "Product Team", icon: faAward, image: "/images/awards/23.png", rating: 4 },
    { year: "2011", title: "Global Outreach Initiative", description: "Initiated programs to reach international markets and clients.", presentedBy: "Marketing Team", icon: faTrophy, image: "/images/awards/24.png", rating: 5 },
    { year: "2012", title: "Enhanced Customer Support Services", description: "Upgraded customer service platforms to improve client satisfaction.", presentedBy: "Support Team", icon: faStar, image: "/images/awards/25.png", rating: 4 },
    { year: "2013", title: "iMatrix Technologies evolved (Pvt) Ltd in 2013", description: "Transitioned to a Private Limited Company, expanding our service portfolio.", presentedBy: "Board of Directors", icon: faCheckCircle, image: "/images/awards/22.png", rating: 3 },
    { year: "2014", title: "TIS Independence Day Drive.", description: "Participated in the national drive to promote independence and unity.", presentedBy: "Community Leaders", icon: faAward, image: "/images/awards/23.png", rating: 4 },
    { year: "2015", title: "Sustainability Initiatives Launched", description: "Introduced sustainable practices across all operations.", presentedBy: "Sustainability Team", icon: faTrophy, image: "/images/awards/24.png", rating: 5 },
    { year: "2016", title: "Received ISO Certification", description: "Achieved ISO certification for quality management.", presentedBy: "Quality Assurance Team", icon: faStar, image: "/images/awards/25.png", rating: 4 },
    { year: "2017", title: "Community Engagement Programs", description: "Launched programs to give back to the community.", presentedBy: "CSR Team", icon: faCheckCircle, image: "/images/awards/26.png", rating: 5 },
    { year: "2018", title: "We ranked 3rd Nationwide by Market TPR.", description: "Achieved recognition as one of the top three companies in market performance.", presentedBy: "Industry Analysts", icon: faTrophy, image: "/images/awards/24.png", rating: 5 },
    { year: "2019", title: "Achieved Award for Top Trader.", description: "Recognized for outstanding trading performance and client satisfaction.", presentedBy: "Trade Association", icon: faStar, image: "/images/awards/25.png", rating: 4 },
    { year: "2020", title: "Highest Value Contributors.", description: "Awarded for significant contributions to client projects and community service.", presentedBy: "Client Testimonials", icon: faCheckCircle, image: "/images/awards/26.png", rating: 5 },
    { year: "2021", title: "Awarded for Customers Acquisition.", description: "Honored for successfully acquiring and retaining a record number of customers.", presentedBy: "Sales Team", icon: faAward, image: "/images/awards/27.png", rating: 5 },
    { year: "2022", title: "TIS Championship League Winner", description: "Won the championship for excellence in industry-specific challenges.", presentedBy: "League Committee", icon: faTrophy, image: "/images/awards/28.png", rating: 4 },
    { year: "2023", title: "Innovative Product Launch", description: "Introduced a groundbreaking product that transformed our service offerings.", presentedBy: "Product Development Team", icon: faLightbulb, image: "/images/awards/22.png", rating: 5 },
    { year: "2024", title: "Best Workplace Award", description: "Recognized as one of the best workplaces in the industry for employee satisfaction and engagement.", presentedBy: "HR Team", icon: faThumbsUp, image: "/images/awards/10.png", rating: 5 },
    { year: "2025", title: "Our journey continues....", description: "Looking forward to new milestones and achievements in the coming years.", presentedBy: "All Employees", icon: faAward, image: "/images/talent-acquisition-hr-manager-onboarding-new-employees_1036975-209046.avif", rating: 4 }
  ];

  const awardImages = [
    "/images/awards/21.png",
    "/images/awards/29.png",
    "/images/awards/3.png",
    "/images/awards/10.png"
  ];

  return (
    <div className="timeline-container">
      <br/>
      <br/>
      <h2>Awards and Accolades</h2>
      <div className="image-row">
        {awardImages.slice(0, 4).map((image, index) => (
          <img 
            key={index} 
            src={image} 
            alt={`Award ${index + 1}`} 
            className="row-image"
          />
        ))}
      </div>
      <p className="timeline-description">
        A timeline of all the honorary accolades received by Tally over the years.
      </p>
      <div className="timeline">
        {awards.map((award, index) => (
          <div className="timeline-item" key={index}>
            <div className="timeline-year">{award.year}</div>
            <div className="timeline-content">
              <div className="content-details" style={{width:'600px'}}>
                <FontAwesomeIcon icon={award.icon} className="timeline-icon" />
                <h3>{award.title}</h3>
                <p>{award.description}</p>
                <p className="timeline-presented">{award.presentedBy}</p>
                <div className="circular-image">
                  <img src={award.image} alt={`Award for ${award.year}`} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Timeline;
