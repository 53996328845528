import React from "react";
import "./TallyPrime.css";
import TallyShowcase from "./TallyShowcase";
// import TallyHome from "./TallyHome";
const TallyPrime = () => {
  return (
    <div className="tallyprime-container">
      {/* Simple to Learn Section */}
 
 <TallyShowcase/>

      <section className="section simple-to-learn">
        <div className="image">
          <img src="/images/unnamed-1-removebg-preview.png" alt="Person celebrating" />
        </div>
        <div className="text">
          <h3>Simple to learn, easier to use</h3>
          <p>
            Getting started with TallyPrime is extremely simple. You can set up
            the application in less than a minute and get immediate access to
            the business features you need.
          </p>
        </div>
      </section>

      {/* Insightful Reports Section */}
      <section className="section insightful-reports">
        <div className="text">
          <h3>Insightful, actionable & customizable reports</h3>
          <p>
            TallyPrime comes with a wide range of readily available business
            reports with powerful and useful business insights.
          </p>
        </div>
        <div className="image">
          <img src="/images/tally/Blog_Effortless-e-Invoicing-with-TallyPrimes-e-Tims-solution-2.jpg" alt="Team working on reports" />
        </div>
      </section>

      {/* Secure Access Section */}
      <section className="section secure-access">
        <div className="image">
          <img src="/images/tally/Tally-on-Cloud-Right-For-Your-Business-1.png" alt="Person with laptop sitting on a rock" />
        </div>
        <div className="text">
          <h3>Anywhere, anytime and secure access</h3>
          <p>
            With TallyPrime, you can access crucial business data from anywhere,
            whenever you need it, ensuring data security at all times.
          </p>
        </div>
      </section>
    </div>
  );
};


export default TallyPrime;
