import React, { useState, useEffect } from "react";

const IMatrixComponent = () => {
  const ceoProfiles = [
    {
      name1: "Venkataraghavan",
      image1: "/images/IMT001.png",
      name2: "Guna",
      image2: "/images/WhatsApp_Image_2024-10-30_at_4.28.58_PM-removebg-preview.png",
    },
    {
      name1: "Guna",
      image1: "/images/WhatsApp_Image_2024-10-30_at_4.28.58_PM-removebg-preview.png",
      name2: "Venkataraghavan",
      image2: "/images/IMT001.png",
    },
  ];

  const [currentProfileIndex, setCurrentProfileIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentProfileIndex((prevIndex) => (prevIndex + 1) % ceoProfiles.length);
    }, 9000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={styles.container}>
      {/* Vertical Column Layout */}
      <div style={styles.column}>
        {/* Left Section - Introduction */}
        <div style={styles.card}>
          <h2 style={styles.title}>Welcome</h2>
          <p style={styles.description}>
            At iMatrix Technologies, we bring innovation and expertise to the
            forefront of your business needs. Established in 2006 and
            incorporated in 2013, we have grown to become a trusted partner for
            companies looking to harness the power of technology in their
            operations. Our team of skilled professionals is dedicated to
            delivering tailored solutions that meet the unique requirements of
            each client.
          </p>
          <h3 style={styles.subtitle}>Why Choose iMatrix Technologies?</h3>
          <ul style={styles.bulletPoints}>
            <li>
              <strong>Proven Expertise:</strong> Over a decade of experience in
              delivering solutions that drive efficiency across various sectors.
            </li>
            <li>
              <strong>Tally 5-Star Partner:</strong> Specializing in seamless
              integration and support for Tally ERP and Tally Prime solutions.
            </li>
            <li>
              <strong>Comprehensive Services:</strong> From ERP implementations
              to web applications and security solutions.
            </li>
            <li>
              <strong>Customer-Centric Approach:</strong> Long-lasting
              relationships ensuring we meet and exceed your business goals.
            </li>
            <li>
              <strong>Global Reach:</strong> Serving clients in India,
              Sri Lanka, and Dubai with world-class support.
            </li>
          </ul>
        </div>

        {/* Right Section - Services */}
        <div style={styles.card}>
          <blockquote style={styles.quote}>
            Whether you're a startup, a growing business, or an established
            enterprise, iMatrix Technologies is your key to success.
          </blockquote>
          <div style={styles.profile}>
            <img
              src={ceoProfiles[currentProfileIndex].image1}
              alt={ceoProfiles[currentProfileIndex].name1}
              style={styles.ceoImage}
              className="fade-in"
            />
            <img
              src={ceoProfiles[currentProfileIndex].image2}
              alt={ceoProfiles[currentProfileIndex].name2}
              style={styles.ceoImage}
              className="fade-in"
            />
          </div>
          <div style={styles.ceoDetails}>
            <p style={styles.ceoName}>
              {ceoProfiles[currentProfileIndex].name1} &{" "}
              {ceoProfiles[currentProfileIndex].name2}
            </p>
          </div>

          <h3 style={styles.subtitle}>Our Solutions</h3>
          <ul style={styles.bulletPoints}>
            <li>
              <strong>Tally ERP & Tally Prime:</strong> Maximize your business
              potential with customized Tally solutions.
            </li>
            <li>
              <strong>Web Application Development:</strong> Create web-based
              solutions tailored to your business needs.
            </li>
            <li>
              <strong>Mobile App Development:</strong> Develop high-performance
              apps for Android & iOS platforms.
            </li>
            <li>
              <strong>IT Infrastructure:</strong> Secure and optimize your IT
              environment with end-to-end solutions.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

// CSS-in-JS styles with improved responsiveness and design
const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "20px",
    backgroundColor: "#f0f4f8", // Light background for better contrast
  },
  column: {
    display: "flex",
    flexDirection: "column", // Column layout for vertical display
    gap: "20px",
    width: "100%",
    maxWidth: "1200px", // Max width for larger screens
  },
  card: {
    backgroundColor: "#ffffff",
    borderRadius: "20px", // Increased rounded corners for a softer look
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    animation: "fadeIn 1s ease-in-out", // Fade-in animation for each card
    transition: "transform 0.3s ease, background 0.3s ease", // Smooth transform and background change on hover
    ":hover": {
      backgroundColor: "linear-gradient(135deg, #0000FF, #2193b0)", // Gradient color on hover
    },
  },
  title: {
    fontSize: "2.5rem",
    marginBottom: "0.5rem",
    fontWeight: "bold",
    color: "#2c3e50", // Darker color for title
  },
  subtitle: {
    fontSize: "1.5rem",
    color: "#34495e", // Darker color for subtitles
    marginTop: "20px",
  },
  description: {
    fontSize: "1.2rem",
    textAlign: "justify",
    marginBottom: "20px",
    lineHeight: "1.7", // Improved line height for readability
    color: "#34495e", // Color for description
  },
  bulletPoints: {
    paddingLeft: "20px",
    fontSize: "1.1rem",
    listStyleType: "disc",
  },
  quote: {
    fontSize: "1.4rem",
    marginBottom: "20px",
    color: "#7f8c8d", // Gray color for the quote
    animation: "slideIn 1s ease-in-out",
    whiteSpace: "normal",
  },
  profile: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    animation: "fadeIn 1s ease-in-out",
  },
  ceoImage: {
    borderRadius: "10%",
    width: "200px",
    height: "200px",
    transition: "transform 0.5s ease",
    animation: "fadeIn 2s ease-in-out",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)", // Shadow effect on images
  },
  ceoDetails: {
    textAlign: "center",
  },
  ceoName: {
    fontWeight: "bold",
    marginBottom: "5px",
    fontSize: "1.8rem", // Increased size for better visibility
    color: "#2c3e50", // Darker color for CEO names
  },
  // Media query for responsive design
  '@media (max-width: 768px)': {
    title: {
      fontSize: "2rem",
    },
    subtitle: {
      fontSize: "1.4rem",
    },
    description: {
      fontSize: "1rem",
    },
    ceoImage: {
      width: "160px",
      height: "160px",
    },
  },
  // Keyframes for animations
  '@keyframes fadeIn': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
  '@keyframes slideIn': {
    '0%': { transform: 'translateY(-30px)', opacity: 0 },
    '100%': { transform: 'translateY(0)', opacity: 1 },
  },
  // Hover effect for images
  hoverEffect: {
    '&:hover': {
      transform: 'scale(1.1)', // Enlarge image on hover
    },
  },
};

// Apply the hover effect style to ceoImage
const ceoImageWithHoverEffect = {
  ...styles.ceoImage,
  ...styles.hoverEffect,
};

export default IMatrixComponent;
