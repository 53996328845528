// src/pages/AutoBackupSoftware.jsx
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt, faFileArchive, faUserFriends, faSyncAlt, faLock } from '@fortawesome/free-solid-svg-icons'; // Add your preferred icons
import './AutoBackupSoftware.css'; // Create a CSS file for styling

// Add your images here
const images = [
  "./1.png", // Replace with actual image paths
  "./2.png",
];

const AutoBackupSoftware = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    // Swap image every 3 minutes (180000 milliseconds)
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 180000);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  const autoBackupSoftware = [
    { 
      name: "AutoBackup Pro", 
      icon: faCloudUploadAlt, 
      description: "Seamlessly backs up your files to the cloud with automatic scheduling.",
      details: "AutoBackup Pro offers continuous data protection, easy recovery options, and supports multiple cloud services."
    },
    { 
      name: "File History Backup", 
      icon: faFileArchive, 
      description: "Keep your files safe with regular backups and easy recovery.",
      details: "This software automatically saves versions of your files, allowing you to restore previous versions with ease."
    },
    { 
      name: "Easy Backup", 
      icon: faUserFriends, 
      description: "User-friendly software that automates backups for all your important data.",
      details: "Easy Backup simplifies the backup process with a straightforward interface and customizable backup schedules."
    },
    { 
      name: "Backup and Sync", 
      icon: faSyncAlt, 
      description: "Real-time file backup and sync across all your devices.",
      details: "This tool allows you to keep your files synchronized across devices while maintaining regular backups."
    },
    { 
      name: "Cloud Backup", 
      icon: faLock, 
      description: "Securely store your files in the cloud for easy access and recovery.",
      details: "Cloud Backup provides end-to-end encryption and is compatible with various cloud storage providers."
    },
    
    // Additional auto-backup software titles with detailed descriptions
    { 
      name: "Secure Backup", 
      icon: faLock, 
      description: "Provides encrypted backup solutions for sensitive data.", 
      details: "Secure Backup uses military-grade encryption to ensure your data remains confidential and safe."
    },
    { 
      name: "Smart Backup", 
      icon: faSyncAlt, 
      description: "Intelligent backup management with incremental backups.", 
      details: "Smart Backup only saves changes made to files after the initial backup, saving time and storage space."
    },
    { 
      name: "Backup Genie", 
      icon: faUserFriends, 
      description: "Magically simplifies the backup process for everyone.", 
      details: "Backup Genie automates the backup process with user-friendly settings and quick recovery options."
    },
    // { 
    //   name: "SafeGuard Backup", 
    //   icon: faLock, 
    //   description: "Offers real-time backup and restoration options.", 
    //   details: "SafeGuard Backup continuously monitors file changes and backs up files in real-time."
    // },
    { 
      name: "SyncBack Pro", 
      icon: faSyncAlt, 
      description: "Professional backup and synchronization tool for any environment.", 
      details: "SyncBack Pro provides advanced options for professionals needing reliable and flexible backup solutions."
    },
  ];

  return (
    <div className="autobackup-software">
      <br />
      <br />
      <br />

      <h1>Auto-Backup Software</h1>

     


      <div className="autobackup-container">
        {autoBackupSoftware.map((software, index) => (
          <div key={index} className="software-card">
            <FontAwesomeIcon icon={software.icon} size="3x" className="software-icon" />
            <h2>{software.name}</h2>
            <p>{software.description}</p>
            <p className="details">{software.details}</p> {/* Added detailed description */}
          </div>
        ))}
      </div>
       {/* Image display */}
       {/* <div className="image-container">
        <img src={images[currentImageIndex]} alt="Auto Backup Software" className="slideshow-image" />
      </div> */}
      <div className="image-container">
  <div className="top-image">
    <img src={images[0]} alt="Auto Backup Software 1" className="slideshow-image" />
  </div>
  <div className="bottom-image-container">
    <img src={images[1]} alt="Auto Backup Software 2" className="slideshow-image-bottom" />
  </div>
</div>
    </div>
  );
};

export default AutoBackupSoftware;
