import React from "react";
import "./SecuritySystem.css"; // Import the CSS for styling

const SecuritySystem = () => {
  const cameraTypes = [
    { name: "Dome & Bullet Cameras", imgSrc: "/images/cctv/3.jpg" },
    { name: "PTZ Cameras", imgSrc: "/images/cctv/1.jpg" },
    { name: "Network/IP Cameras", imgSrc: "/images/cctv/2.jpg" },
    { name: "Wireless Cameras", imgSrc: "/images/cctv/4.jpg" },
    { name: "Analog Camera", imgSrc: "/images/cctv/61cV3MWS5OL.jpg" },
    { name: "HD Cameras", imgSrc: "/images/cctv/hd-cctv-camera.jpg" },
    { name: "4G Cameras", imgSrc: "/images/cctv/IMG_2561-no-logo-scaled-1-800x533.webp" },
    { name: "NVR/DVR Cameras", imgSrc: "/images/cctv/1-4g-sim-linkage-camera-indoor-outdoor-security-camera-trueview-original-imah3yg3ggvewfcr.webp" },
  ];

  return (
    <div className="security-container">
      <br />
      <br />
      <h1>Secure Your Space with Advanced Premium CCTV Solutions</h1>
      <p className="description">
        At iMatrix Technologies, we offer a seamless online booking experience for our exceptional CCTV camera services. Our commitment to prompt delivery and quick installation ensures your home and commercial spaces in Chennai, India, are secure in no time. We provide comprehensive post-sales support, including assistance with warranty claims and annual maintenance contracts. Additionally, our services include data recovery, password management, and backup solutions for recorded footage.
      </p>

      <div className="services">
        <div className="service-box">
          <img src="/images/cctv/blurred-shop-store-background-captured-by-cctv-security-panorama_908985-57088.avif" alt="Top CCTV Camera" />
          <h3>Top CCTV Cameras</h3>
          <p>
  iMatrix Technologies partners with leading brands such as Hikvision, HiFocus, D-Link, Honeywell, and CP Plus, offering a diverse range of CCTV camera types, including Dome, Bullet, PTZ, and Wi-Fi cameras, to meet every security need.
</p>

        </div>
        <div className="service-box">
          <img src="/images/cctv/pngtree-cctv-camera-in-3d-rendering-image_3674737.jpg" alt="Southern Surveillance" />
          <h3>Surveillance Services</h3>
          <p>
            We provide professional CCTV security system installation and services throughout Tamil Nadu, ensuring safety in all areas and nearby locations.
          </p>
        </div>
        <div className="service-box">
          <img src="/images/cctv/young-couple-using-touchpad-with-insurance-agent-while-making-loan-repayment-plan-meeting.jpg" alt="CCTV Consultation" />
          <h3>CCTV Consultation</h3>
          <p>
            Our expert team at iMatrix Technologies offers high-resolution surveillance systems for both residential and commercial applications, tailored from single-camera setups to multi-site configurations for optimal security.
          </p>
        </div>
      </div>

      <div className="services">
        <div className="service-box">
          <h3>DVR & NVR Solutions</h3>
          <p>
            We provide comprehensive solutions for Digital Video Recorders (DVR) and Network Video Recorders (NVR), ensuring seamless video management for your surveillance needs.
          </p>
        </div>
        <div className="service-box">
          <h3>Surveillance HDDs</h3>
          <p>
            Our high-capacity hard drives are specifically designed for CCTV and surveillance recording, offering reliable storage for your critical footage.
          </p>
        </div>
        <div className="service-box">
          <h3>CCTV Accessories</h3>
          <p>
            Enhance your CCTV installations with our wide range of accessories, including cables, Lens mounts, and power supplies to ensure a robust security setup.
          </p>
        </div>
      </div>

      <h2 className="camera-types-title">Explore Our CCTV Camera Options</h2>
      <div className="camera-types">
        {cameraTypes.map((camera, index) => (
          <div key={index} className="camera-box">
            <img src={camera.imgSrc} alt={camera.name} />
            <h3>{camera.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SecuritySystem;
