import React, { useState, useEffect } from 'react';
import './MobileDeveloper.css';
import './TechnologyUsed.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const MobileDeveloper = () => {
  // State variables for image indices
  const [currentImageIndexTransport, setCurrentImageIndexTransport] = useState(0);
  const [currentImageIndexPR, setCurrentImageIndexPR] = useState(0);

  const transportImages = [
    '/images/webimage/1.png',
    '/images/webimage/2.png',
    '/images/webimage/3.png',
    '/images/webimage/4.png',
    '/images/webimage/5.png',
  ];

  const prImages = [
    '/images/prmodel/1.jpg',
    '/images/prmodel/2.jpg',
    '/images/prmodel/3.jpg',
    '/images/prmodel/4.jpg',
    '/images/prmodel/5.jpg',
    '/images/prmodel/6.jpg',
    '/images/prmodel/7.jpg',
  ];

  // Auto slide every 3 seconds
  useEffect(() => {
    const transportInterval = setInterval(() => {
      setCurrentImageIndexTransport((prevIndex) =>
        prevIndex === transportImages.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    const prInterval = setInterval(() => {
      setCurrentImageIndexPR((prevIndex) =>
        prevIndex === prImages.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => {
      clearInterval(transportInterval);
      clearInterval(prInterval);
    };
  }, [transportImages.length, prImages.length]);

  return (
    <>
     {/* Header Section */}
{/* Header Section */}
<header className="header-section">
  <div className="mobile-shop-image">
    <img src="/images/mobile-application.png" alt="Mobile Shop" />
  </div>
  <div className="header-content">
  <h1 style={{marginTop:'50px'}}>Mobile and Windows Application Development</h1>
<p>
  Leveraging state-of-the-art technology, we create comprehensive and high-quality applications
  that exceed client expectations. Our developers excel in frameworks such as Android SDK, Java, XML, 
  and more for Android applications. For iOS, we utilize Swift and Objective-C, ensuring seamless 
  performance and user experience. Additionally, our expertise extends to Windows applications, 
  where we leverage technologies like .NET and UWP to deliver robust and innovative solutions.
</p>


    {/* Additional Content with Icons */}
    <div className="features">
      <div className="feature-item">
        <i className="fas fa-code"></i>
        <h4>Custom Development</h4>
        <p>Tailored applications to meet your unique business needs.</p>
      </div>
      <div className="feature-item">
        <i className="fas fa-mobile-alt"></i>
        <h4>Cross-Platform Support</h4>
        <p>Build applications that work seamlessly across devices.</p>
      </div>
      <div className="feature-item">
        <i className="fas fa-lock"></i>
        <h4>Data Security</h4>
        <p>Implement robust security measures to protect your data.</p>
      </div>
      <div className="feature-item">
        <i className="fas fa-chart-line"></i>
        <h4>Performance Optimization</h4>
        <p>Ensure fast loading times and smooth user experiences.</p>
      </div>
    </div>
  </div>
</header>


      {/* App Design and Development Services */}
      <section className="tech-section">
        <div className="container2">
          <div className="technology-used">
            <h2>Technology We Used</h2>
            <p>
              Technology stacks are the backbone of your architected software. We build your software
              with the latest and top core technologies, ensuring optimal performance and scalability.
            </p>
            <div className="tech-icons">
              <img src="images/mobile/mobileapps/xd.png" alt="Adobe XD" />
              <img src="images/mobile/mobileapps/figma.png" alt="Figma" />
              <img src="images/aaaaaaaaaaaaav.jpg" alt="Kotlin" />
              <img src="images/mobile/mobileapps/react.png" alt="React Native" />
              <img src="images/mobile/mobileapps/flutter_icon.png" alt="Flutter" />
              <img src="images/mobile/mobileapps/sql.png" alt="MySQL" />
            </div>
          </div>

          <section className="section transport-section">
            <div className="video">
              <video className="slider-video" controls autoPlay loop muted>
                <source src="/images/video/2.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="text">
              <h3>Calls Application</h3>
              <p>
                Our Calls Application offers seamless connectivity, enabling users to make high-quality voice and video calls
                with robust features like call recording and conferencing. Enhance communication within your organization
                with our reliable solution.
              </p>
            </div>
          </section>

          <section className="section pr-purchase-section">
            <div className="text">
              <h3>Transport Logistics Application</h3>
              <p>
                The Transport Application simplifies logistics management, providing real-time tracking of shipments and 
                efficient route planning. Improve your delivery times and customer satisfaction with our innovative 
                transport solution.
              </p>
            </div>
            <div className="video">
              <video className="slider-video" controls autoPlay loop muted>
                <source src="/images/video/1.mp4" type="video/mp4" style={{ width: '60%', height: '50px' }} />
                Your browser does not support the video tag.
              </video>
            </div>
          </section>
        </div>
      </section>

      {/* Android and iOS Development Services */}
      <div className="services-section" style={{ display: 'flex', justifyContent: 'space-between', width: '97%' }}>
        <div className="android-apps" style={{ flex: '1', marginRight: '20px' }}>
          <h3><i className="fab fa-android"></i> Android Apps</h3>
          <p>
            We provide top-notch Android development services, ensuring your ideas come to life as highly-functional, 
            responsive mobile applications that meet the diverse needs of users.
          </p>
        </div>
        <div className="android-image" style={{ flex: '1' }}>
          <img src="images/mobile/mobileapps/android.webp" alt="Android Development" style={{ width: '60%', height: '250px' }} />
        </div>
      </div>

      <div className="services-section" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '40px', width: '97%' }}>
        <div className="ios-apps" style={{ flex: '1', marginRight: '20px' }}>
          <h3><i className="fab fa-apple"></i> iOS Apps</h3>
          <p>
            Our iOS development services ensure your app is highly-functional and optimized with all necessary 
            user tracking and social networking features, tailored to provide an exceptional user experience.
          </p>
        </div>
        <div className="ios-image" style={{ flex: '1' }}>
          <img src="images/mobile/mobileapps/ios.webp" alt="iOS Development" style={{ width: '60%', height: '250px' }} />
        </div>
        
      </div>

      <div className="services-section" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '40px', width: '97%' }}>
        <div className="ios-apps" style={{ flex: '1', marginRight: '20px' }}>
        <h3><i className="fab fa-windows"></i> Windows Apps</h3>
          <p>
      Our Windows development services focus on creating powerful applications that leverage the latest technologies, 
      ensuring a seamless experience across various devices while integrating essential features for enhanced productivity.
    </p>
        </div>
        <div className="windows-image" style={{ flex: '1',borderRadius:'5px' }}>
    <img src="images/Microsoft-terminará-el-soporte-de-Windows-10-Mobile-en-Diciembre-800x421.jpg" alt="Windows Development" style={{ width: '60%', height: '250px', borderRadius:'5px' }} />
  </div>
      </div>
         
 
    </>
  );
};

export default MobileDeveloper;
